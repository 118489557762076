import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';
import { getLang } from '../../../environnement';

export default function UpdateClientDialog({
  onClose,
  open,
  onClickUpdateClient,
  client,
  errors,
}) {
  const [lang] = useState('FR');

  const [nom, setNom] = useState(client.nom);
  const [prenom, setPrenom] = useState(client.prenom);
  const [email, setEmail] = useState(client.email);
  const [phone, setPhone] = useState(client.phone);
  const [birthDate, setBirthDate] = useState(client.birthDate);

  useEffect(() => {
    setNom(client.nom);
    setPrenom(client.prenom);
    setEmail(client.email);
    setPhone(client.phone);
    setBirthDate(client.birthDate);
  }, [client]);
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} fullWidth>
        <DialogTitle>{getLang(lang).clients.updateClientsDialog.updateClient}</DialogTitle>
        <Grid container spacing={2}>
          {/* nom */}
          <Grid item xs={6}>
            <TextField
              id="nom"
              label={getLang(lang).clients.updateClientsDialog.lastName}
              variant="outlined"
              margin='dense'
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              error={errors && errors.nom}
              helperText={errors && errors.nom ? errors.nom : ''}
              fullWidth
            />
          </Grid>
          {/* prénom */}
          <Grid item xs={6}>
            <TextField
              id="prenom"
              label={getLang(lang).clients.updateClientsDialog.firstName}
              variant="outlined"
              margin='dense'
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              error={errors && errors.prenom}
              helperText={errors && errors.prenom ? errors.prenom : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* email */}
          <Grid item xs={6}>
            <TextField
              id="email"
              type='email'
              label={getLang(lang).clients.updateClientsDialog.email}
              variant="outlined"
              margin='dense'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errors && errors.email}
              helperText={errors && errors.email ? errors.email : ''}
              fullWidth
            />
          </Grid>
          {/* phone */}
          <Grid item xs={6}>
            <TextField
              id="phone"
              type='tel'
              label={getLang(lang).clients.updateClientsDialog.phone}
              variant="outlined"
              margin='dense'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={errors && errors.phone}
              helperText={errors && errors.phone ? errors.phone : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* birthDate */}
        <Grid container>
          <TextField
            id="birthDate"
            type='date'
            label={getLang(lang).clients.updateClientsDialog.birthDate}
            variant="outlined"
            margin='dense'
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            error={errors && errors.birthDate}
            helperText={errors && errors.birthDate ? errors.birthDate : ''}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={onClose}
              fullWidth
            >
              {getLang(lang).clients.updateClientsDialog.cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              size='large'
              onClick={() => onClickUpdateClient({
                id: client.id,
                nom,
                prenom,
                email,
                phone,
                birthDate,
              })}
              fullWidth
            >
              {getLang(lang).clients.updateClientsDialog.update}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '20px',
          paddingTop: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: '5px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: '10px',
        }
      }
    }
  },
});