import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { getLang } from '../../../environnement';

export default function DeleteClientConfirmDialog({
  onClose,
  open,
  onClickDeleteClient,
}) {
  const [lang] = useState('FR');

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{getLang(lang).clients.deleteClientConfirmDialog.deleteClient}</DialogTitle>
      <DialogContent>{getLang(lang).clients.deleteClientConfirmDialog.deleteConfirmationContent}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>{getLang(lang).clients.deleteClientConfirmDialog.cancel}</Button>
        <Button variant='contained' onClick={onClickDeleteClient}>{getLang(lang).clients.deleteClientConfirmDialog.delete}</Button>
      </DialogActions>    
    </Dialog>
  );
}
