import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import { userSelector } from './utils/store/userSlice';
import AdminHeader from './utils/components/header';
import getEnvironement, { getLang } from './environnement';
import searchIcon from './utils/assets/icons/search-normal.png';
import ManagersTable from './utils/components/tables/managersTable';
import AddManagerDialog from './utils/components/dialog/addManagerDialog';
import UpdateManagerDialog from './utils/components/dialog/updateManagerDialog';
import DeleteManagerConfirmDialog from './utils/components/dialog/deleteManagerConfirmDialog';

export default function AdminManagers() {
  const user = useSelector(userSelector);
  const [lang] = useState('FR');

  const [allManagers, setAllManagers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isEmptyManagers, setIsEmptyManagers] = useState(false);

  const [openAddManagerDialog, setOpenAddManagerDialog] = useState(false);
  const [addManagerErrors, setAddManagerErrors] = useState(null);
  
  const [openUpdateManagerDialog, setOpenUpdateManagerDialog] = useState(false);
  const [updateManagerErrors, setUpdateManagerErrors] = useState(null);
  const [updatedManager, setUpdatedManager] = useState([]);

  const [openDeleteManagerConfirmDialog, setOpenDeleteManagerConfirmDialog] = useState(false);
  const [deletedManagerId, setdeletedManagerId] = useState();

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  const getManagers = async () => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/managers`;
    await axios.get(url)
      .then((response) => {
        setAllManagers(response.data);
        setManagers(response.data);
        if (response.data.length == 0) {
          setIsEmptyManagers(true);
        } else {
          setIsEmptyManagers(false);
        }
      })
      .then(() => setCircularProgress(false))
      .catch((err) => {
        console.log(err);
      });

  };

  const getManager = async (id) => {
    setOpenBackDrop(true);
    const url = `${getEnvironement().API_URL}/admin/managers/${id}`;
    await axios.get(url)
      .then((response) => {
        setUpdatedManager(response.data);
      })
      .then(() => setOpenBackDrop(false))
      .then(() => setOpenUpdateManagerDialog(true))
      .catch((err) => {
        console.log(err);
      });
  };

  const addManager = async (manager) => {
    const url = `${getEnvironement().API_URL}/admin/managers`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, manager, config)
      .then(() => setAddManagerErrors(null))
      .then(() => setOpenAddManagerDialog(false))
      .then(() => getManagers())
      .then(() => {
        toast.success('Le géstionnaire a bien été ajouté', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        setAddManagerErrors(err.response.data.errors);
      });
  };

  const updateManager = async (manager) => {
    const url = `${getEnvironement().API_URL}/admin/managers/${manager.id}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(url, manager, config)
      .then(() => setUpdateManagerErrors(null))
      .then(() => setOpenUpdateManagerDialog(false))
      .then(() => getManagers())
      .then(() => {
        toast.success('Le géstionnaire a bien été modifié', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        setUpdateManagerErrors(err.response.data.errors);
      });
  };

  const deleteManager = async (idManager) => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/managers/${idManager}`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.delete(url, config)
      .then(() => getManagers())
      .then(() => setCircularProgress(false))
      .then(() => {
        toast.success('Le géstionnaire a bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch(() => {
        getManagers();
        setCircularProgress(false);
        toast.error('Merci de supprimer tous les clients liés à ce géstionnaire avant de le supprimer', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const handelChangeSearchInput = (e) => {
    setManagers(allManagers.filter(manager => manager.nom.startsWith(e.target.value)));
  };

  useEffect(() => {
    getManagers();
  }, []);

  return (
    <div className='py-4'>
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddManagerDialog
        open={openAddManagerDialog}
        onClose={() => {setOpenAddManagerDialog(false); setAddManagerErrors(null);}}
        onClickAddManager={addManager}
        errors={addManagerErrors}
      />
      <UpdateManagerDialog
        open={openUpdateManagerDialog}
        onClose={() => {setOpenUpdateManagerDialog(false); setUpdateManagerErrors(null);}}
        onClickUpdateManager={updateManager}
        manager={updatedManager}
        errors={updateManagerErrors}
      />
      <DeleteManagerConfirmDialog
        open={openDeleteManagerConfirmDialog}
        onClose={() => setOpenDeleteManagerConfirmDialog(false)}
        onClickDeleteManager={() => {
          deleteManager(deletedManagerId);
          setOpenDeleteManagerConfirmDialog(false);
        }}
      />
      <AdminHeader name={user.nom} />
      <div className='flex justify-between items-center mb-5'>
        <p className='font-poppins font-medium text-2xl'>Tous les géstionnaires</p>
        <Button
          variant='contained'
          onClick={() => setOpenAddManagerDialog(true)}
        >
          + Ajouter un nouveau géstionnaire
        </Button>
      </div>
      <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
        <img src={searchIcon}/>
        <input
          type='search'
          placeholder={getLang(lang).clients.searchByName}
          onChange={(e) => handelChangeSearchInput(e)}
          className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
        />
      </div>
      <ManagersTable
        header={[
          'Id_géstionnaire',
          'Nom',
          'Prénom',
          'Email',
          'Téléphone',
          'Date',
          'Nombre clients',
          'Actions'
        ]}
        data={managers}
        onClickUpdateManager={(idManager) => getManager(idManager)}
        onClickDeleteManager={(idManager) => {
          setOpenDeleteManagerConfirmDialog(true);
          setdeletedManagerId(idManager);
        }}
      />
      {isEmptyManagers && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>Aucun géstionnaire</div>
      )}
      {circularProgress && (
        <div className='flex justify-center p-5'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
