import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector } from './store/userSlice';
import { ContextProvider } from './context';

export default function AdminPage({page}) {
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);
  return (
    <ContextProvider>
      {page}
    </ContextProvider>
  );
}
