import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import HeaderContainer from '../utils/components/headerContainer/headerContainer';
import getEnvironement, { getLang } from '../environnement';
import { setUser } from '../utils/store/userSlice';
import './login.css';

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resterConnecte, setResterConnecte] = useState(false);
  const [errorLogin, setErrorLogin] = useState('');

  const [lang] = useState('FR');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const [status, setStatus] = useState(location.state?.data || '');


  useEffect(() => {
    setTimeout(() => setStatus(''), 4000);
  }, [status]);

  const handelLogin = () => {
    const url = `${getEnvironement().API_URL}/admin/login`;
    const data = {
      email,
      password,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
          navigate('/');
        }
      })
      .catch((err) => {
        setErrorLogin(err.response.data.errors?.email || err.response.data.errors?.password);
        setStatus('error-login');
      });
  };

  return (
    <div className='login-container'>
      <HeaderContainer />
      <div className='login'>
        {status === 'error-login'
          && (<Alert severity="error" className='alert'>{errorLogin}</Alert>)}
        <div className='login-form pb-5'>
          <p className='title'>{getLang(lang).login.connexion}</p>
          <label htmlFor='email' className='email'>
            {getLang(lang).login.input.email}
            <TextField
              id="email"
              variant="outlined"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor='password' className='password'>
            {getLang(lang).login.input.password}
            <TextField
              id="password"
              variant="outlined"
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resterConnecte}
                onChange={(e) => setResterConnecte(e.target.checked)}
              />
            }
            label={getLang(lang).login.stayConnected}
            labelPlacement="end"
            className='resterConnecte'
          />
          <Button
            className='login-button'
            variant='contained'
            onClick={handelLogin}
          >
            {getLang(lang).login.connect}
          </Button>
        </div>
      </div>
      
    </div>
  );
}
