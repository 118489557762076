import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
// import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
// import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';

export default function AdminChart({
  data
}) {
  const dataset = [
    { line: data && data[1], bar: data && data[1], month: 'Jan' },
    { line: data && data[2], bar: data && data[2], month: 'Feb' },
    { line: data && data[3], bar: data && data[3], month: 'Mar' },
    { line: data && data[4], bar: data && data[4], month: 'Apr' },
    { line: data && data[5], bar: data && data[5], month: 'Mai' },
    { line: data && data[6], bar: data && data[6], month: 'Jun' },
    { line: data && data[7], bar: data && data[7], month: 'Jul' },
    { line: data && data[8], bar: data && data[8], month: 'Aug' },
    { line: data && data[9], bar: data && data[9], month: 'Sept' },
    { line: data && data[10], bar: data && data[10], month: 'Oct' },
    { line: data && data[11], bar: data && data[11], month: 'Nov' },
    { line: data && data[12], bar: data && data[12], month: 'Dec' },
  ];
  
  const series = [
    { type: 'line', dataKey: 'line', color: 'rgba(0, 0, 0, 0.3)', strokeWidth: 1 },
    { type: 'bar', dataKey: 'bar', color: '#f5f3f4', yAxisKey: 'rightAxis' },
  ];

  return (
    <Stack sx={{ width: '100%', backgroundColor: '#E6F1FE', borderRadius: 5, height: '80%'}}>
      <Box sx={{ width: '100%', height: '100%'}}>
        <ResponsiveChartContainer
          series={series}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              label: 'Mois',
            },
          ]}
          yAxis={[
            { id: 'leftAxis', reverse:  false},
            { id: 'rightAxis', reverse: false},
          ]}
          dataset={dataset}
        >
          {/* <ChartsGrid horizontal /> */}
          <BarPlot />
          <LinePlot />
          <MarkPlot />

          <ChartsXAxis
            formatter={({ xIndex, value }) => {
              const month = dataset[xIndex].month;
              return `${month}: ${value}`;
            }}
          />
          {/* <ChartsYAxis axisId="leftAxis" label="temerature (°C)" />
          <ChartsYAxis
            axisId="rightAxis"
            position="right"
            label="precipitation (mm)"
          /> */}
          <ChartsTooltip />
        </ResponsiveChartContainer>
      </Box>
    </Stack>
  );
}
