import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import productIdIcon from '../../../utils/assets/icons/productIdIcon.png';
// import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

function StatusTable({
  header,
  data,
  onClickDeleteStatus,
  onClickUpdateStatus
}) {
  // const navigate = useNavigate();
  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th 
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                <td className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                  <img src={productIdIcon} />
                  <p className='ml-3 max-w-14 break-words'>{item.id}</p>
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{item.status}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</td>
                <td className='py-5 text-center'>
                  <Tooltip title='Modifier'>
                    <IconButton onClick={() => onClickUpdateStatus(item.id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Supprimer'>
                    <IconButton onClick={() => onClickDeleteStatus(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default StatusTable;