import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

import CustomTextInput from './utils/components/inputs/customTextInput/customTextInput';
import usericon from './utils/assets/icons/usericon.png';
import bday from './utils/assets/icons/bday.png';
import phone from './utils/assets/icons/phone.png';
import emailicon from './utils/assets/icons/email.png';
import password from './utils/assets/icons/passicon.png';
import { setUser, userSelector } from './utils/store/userSlice';
import getEnvironement from './environnement';
import AdminHeader from './utils/components/header';

export default function AdminProfile() {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(user?.prenom);
  const [lastName, setLastName] = useState(user?.nom);
  const [birthDate, setBirthDate] = useState(user?.birthDate);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleUpdate = () => {
    if (newPassword !== confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }
    
    const url = `${getEnvironement().API_URL}/updateUser`;
    const formData = new FormData();
    formData.append('id', user?.id);
    formData.append('nom', lastName);
    formData.append('prenom', firstName);
    formData.append('email', email);
    formData.append('phone', phoneNumber);
    formData.append('password', newPassword);
    formData.append('confirmPassword', confirmPassword);
    formData.append('birthDate', birthDate);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    axios.post(url, formData, config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data.user));
          toast.success('Votre compte a bien été mis à jour.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Une erreur s\'est produite lors de la mise à jour de votre compte. Veuillez réessayer plus tard.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  return (
    <div className='w-full mb-10 lg:mb-0 py-4'>
      <ToastContainer />
      <AdminHeader name={user.nom} />
      <p className='font-poppins font-medium text-2xl'>Informations sur le compte</p>
      <div className='flex flex-col lg:flex-row mb-3 mt-10'>
        <div className='w-full space-y-3'>
          <p>Nom</p>
          <CustomTextInput
            value={lastName}
            setValue={setLastName}
            placeholder="Nom"
            image={usericon}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Prénom</p>
          <CustomTextInput
            value={firstName}
            setValue={setFirstName}
            placeholder="Prénom"
            image={usericon}
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row mb-3'>
        <div className='w-full space-y-3'>
          <p>Date de naissance</p>
          <CustomTextInput
            value={birthDate}
            setValue={setBirthDate}
            placeholder="Date de naissance"
            type="date"
            image={bday}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Numéro de téléphone</p>
          <CustomTextInput
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder="Numéro de téléphone"
            image={phone}
          />
        </div>
      </div>
      <div className='space-y-3 mb-3'>
        <p>Email</p>
        <CustomTextInput
          value={email}
          setValue={setEmail}
          placeholder="Email"
          image={emailicon}
        />
      </div>
      <div className='flex flex-col lg:flex-row mb-3'>
        <div className='w-full space-y-3'>
          <p>Nouveau mot de passe</p>
          <CustomTextInput
            value={newPassword}
            setValue={setNewPassword}
            placeholder="Nouveau mot de passe"
            type="password"
            image={password}
          />
        </div>
        <div className='w-full space-y-3'>
          <p>Confirmer le mot de passe</p>
          <CustomTextInput
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="Confirmer le mot de passe"
            type="password"
            image={password}

          />
        </div>
      </div>
      <div className='mt-5'>
        <Button variant='contained' onClick={handleUpdate}>Modifier</Button>
      </div>
    </div>
  );
}