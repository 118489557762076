import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/userSlice';
import AdminSidebar from './sideBar';
import AdminContent from './content';

export default function Admin() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState('dashboard');

  const user = useSelector(userSelector);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return user && (
    <div className="flex bg-white max-h-screen overflow-hidden">
      <AdminSidebar
        isCollapsed={isCollapsed}
        handleToggle={handleToggle}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
      />
      <AdminContent activeTab={activeTab} />
    </div>
  );
}
