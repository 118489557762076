import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import getEnvironement from './environnement';
import usericon from './utils/assets/icons/usericon.png';
import bday from './utils/assets/icons/bday.png';
import phoneIcon from './utils/assets/icons/phone.png';
import emailicon from './utils/assets/icons/email.png';
import logo from './utils/assets/images/LOGO-LS@2x.png';
import CustomTextInput from './utils/components/inputs/customTextInput/customTextInput';
import ProductsTable from './utils/components/tables/productsTable';
import DetailsProductDialog from './utils/components/dialog/detailsProductDialog';
import UserPacksTable from './utils/components/tables/userPackTable';
import CustomSelect from './utils/components/inputs/customSelect';
import { userSelector } from './utils/store/userSlice';

export async function loader({params}) {
  let client = [];
  const url = `${getEnvironement().API_URL}/admin/users/${params.clientId}`;
  await axios.get(url)
    .then((response) => {
      client = response.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return {client};
}

export default function DetailsClient() {
  const user = useSelector(userSelector);
  const {client} = useLoaderData();

  const [prenom, setPrenom] = useState(client?.prenom);
  const [nom, setNom] = useState(client?.nom);
  const [birthDate, setBirthDate] = useState(client?.birthDate);
  const [phone, setPhone] = useState(client?.phone);
  const [email, setEmail] = useState(client?.email);

  const [updateClientErrors, setUpdateClientErrors] = useState(null);

  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const [openDetailsProductDialog, setOpenDetailsProductDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [detailsProduct, setDetailsProduct] = useState();

  const [packs, setPacks] = useState([]);

  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(client.manager_id);

  const navigate = useNavigate();

  const updateClient = async (client) => {
    const url = `${getEnvironement().API_URL}/admin/users/${client.id}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(url, client, config)
      .then(() => setUpdateClientErrors(null))
      .then(() => {
        toast.success('client a bien été modifié', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        setUpdateClientErrors(err.response.data.errors);
      });
  };

  const getProducts = async (idClient) => {
    const url = `${getEnvironement().API_URL}/admin/products`;
    const data = {idClient};
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, data, config)
      .then((response) => {
        setProducts(response.data);
      });
  };

  const getDetailsProduct = async (productId) => {
    const url = `${getEnvironement().API_URL}/admin/products/${productId}`;
    await axios.post(url)
      .then((response) => setDetailsProduct(response.data))
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductName = (product_id) => {
    const productsName = {
      'CE': 'entreprise',
      'CS': 'monStatus',
      'CA': 'associations',
      'CAE': 'autoEntreprise',
      'CG': 'carteGrise',
      'CHS': 'changementStatut',
      'C': 'cession',
      'D': 'dissolution',
    };
    return productsName[product_id.split('-')[0]];
  };

  const deleteProduct = async (product_id) => {
    const url = `${getEnvironement().API_URL}/${getProductName(product_id)}/${product_id}`;
    const data = {
      user_id: client.id,
      isDemandeAnnulation: false,
    };
    await axios.delete(url, {data})
      .then(() => getProducts(client.id))
      .then(() => {
        toast.success('produit a bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const addStatus = async (selectedIndex, idProduit) => {
    const url = `${getEnvironement().API_URL}/admin/productStatus`;
    const data = {
      idStatus: selectedIndex,
      idProduit
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, data, config)
      .then(() => getDetailsProduct(selectedProduct))
      .then(() => getProducts(client.id))
      .then(() => {
        toast.success('status bien été ajouté', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const deleteStatus = async (selectedIndex, idProduit) => {
    const url = `${getEnvironement().API_URL}/admin/productStatus`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        idStatus: selectedIndex,
        idProduit
      }
    };
    await axios.delete(url, config)
      .then(() => getDetailsProduct(selectedProduct))
      .then(() => getProducts(client.id))
      .then(() => {
        toast.success('status bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const addDocument = async (file, fileName) => {
    if (file) {
      const url = `${getEnvironement().API_URL}/admin/productDocument`;
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('fileName', fileName);
      formData.append('product_id', selectedProduct);
      formData.append('id', products.filter((product) => product.product_id === selectedProduct)[0].id);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      await axios.post(url, formData, config)
        .then(() => getDetailsProduct(selectedProduct))
        .then(() => getProducts(client.id))
        .then(() => {
          toast.success('document bien été ajouté', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
        });
    }
  };

  const deleteDocument = async (idDocument) => {
    const url = `${getEnvironement().API_URL}/admin/productDocument/${idDocument}`;
    await axios.delete(url)
      .then(() => getDetailsProduct(selectedProduct))
      .then(() => getProducts(client.id))
      .then(() => {
        toast.success('document bien été supprimé', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const getPacks = async (idClient) => {
    const url = `${getEnvironement().API_URL}/admin/userPacks`;
    const data = {idClient};
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, data, config)
      .then((response) => {
        setPacks(response.data);
      })
      .catch((err) => console.log(err));
  };

  const getManagers = async () => {
    const url = `${getEnvironement().API_URL}/admin/managers`;
    await axios.get(url)
      .then((response) => {
        setManagers([{id: 1, nom : 'LegalStation', prenom: ''}, ...response.data]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (selectedProduct !== '') {
      getDetailsProduct(selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    getManagers();
  }, []);

  useEffect(() => {
    getProducts(client.id);
    getPacks(client.id);
  }, []);
  return (
    <div>
      <ToastContainer />
      <DetailsProductDialog
        open={openDetailsProductDialog}
        onClose={() => setOpenDetailsProductDialog(false)}
        produit={detailsProduct}
        idProduit={products.filter((product) => product.product_id === selectedProduct)[0]?.id}
        product_id={selectedProduct}
        onClickSaveStatus={addStatus}
        onClickDeleteStatus={deleteStatus}
        onClickSaveDocument={addDocument}
        onClickDeleteDocument={deleteDocument}
      />
      <div className='py-5 px-[5vw] shadow-md mb-10'>
        <img className='w-[15vw] cursor-pointer' src={logo} alt='legalStationLogo' onClick={() => navigate('/')}/>
      </div>
      <div className='px-[5vw]'>
        <div className='flex justify-between items-center mb-5'>
          <p className='font-poppins font-medium text-2xl'>Client</p>
          <Button
            variant='contained'
            onClick={() => {
              updateClient({
                id: client.id,
                nom,
                prenom,
                email,
                phone,
                birthDate,
                manager: selectedManager
              });
            }}
          >
            Enregistrer les modifications
          </Button>
        </div>
        <div className='flex flex-col lg:flex-row my-5'>
          <div className='w-full space-y-3'>
            <p>Nom</p>
            <CustomTextInput
              value={nom}
              setValue={setNom}
              placeholder="Nom"
              image={usericon}
              error={updateClientErrors && updateClientErrors.nom ? updateClientErrors.nom : null}
            />
          </div>
          <div className='w-full space-y-3'>
            <p>Prénom</p>
            <CustomTextInput
              value={prenom}
              setValue={setPrenom}
              placeholder="Prénom"
              image={usericon}
              error={updateClientErrors && updateClientErrors.prenom ? updateClientErrors.prenom : null}
            />
          </div>
        </div>
        <div className='flex flex-col lg:flex-row mb-3'>
          <div className='w-full space-y-3'>
            <p>Date de naissance</p>
            <CustomTextInput
              value={birthDate}
              setValue={setBirthDate}
              placeholder="Date de naissance"
              type="date"
              image={bday}
              error={updateClientErrors && updateClientErrors.birthDate ? updateClientErrors.birthDate : null}
            />
          </div>
          <div className='w-full space-y-3'>
            <p>Numéro de téléphone</p>
            <CustomTextInput
              value={phone}
              setValue={setPhone}
              placeholder="Numéro de téléphone"
              image={phoneIcon}
              error={updateClientErrors && updateClientErrors.phone ? updateClientErrors.phone : null}
            />
          </div>
        </div>
        <div className='space-y-3 mb-3'>
          <p>Email</p>
          <CustomTextInput
            value={email}
            setValue={setEmail}
            placeholder="Email"
            image={emailicon}
            error={updateClientErrors && updateClientErrors.email ? updateClientErrors.email : null}
          />
        </div>
        {user.role_id === 1 && (
          <div className='space-y-3 mb-3'>
            <p>Géstionnaire</p>
            <CustomSelect
              items={managers.map((manager) => {
                return {label: manager.nom + ' ' + manager.prenom, value: manager.id};
              })}
              value={selectedManager}
              setValue={(value) => setSelectedManager(value)}
            />
          </div>
        )}
        <div className='flex'>
          <p
            className={`font-poppins font-medium text-2xl mt-8 mb-2 hover:text-primary cursor-pointer ${activeTab == 1 && 'text-primary'}`} onClick={() => setActiveTab(1)}>Produits</p>
          <p className='font-poppins font-medium text-2xl mt-8 mb-2 mx-3'>|</p>
          <p className={`font-poppins font-medium text-2xl mt-8 mb-2 hover:text-primary cursor-pointer ${activeTab == 2 && 'text-primary'}`} onClick={() => setActiveTab(2)}>Packs</p>
        </div>
        { activeTab == 1 && (
          <ProductsTable
            header={[
              'Id_produit', 'Type produit','Date', 'Status', 'Documents', 'Actions'
            ]}
            data={products}
            onClickDetails={(idProduit) => {
              setSelectedProduct(idProduit);
              setOpenDetailsProductDialog(true);
            }}
            onClickDeleteProduct={deleteProduct}
          />
        )}
        { activeTab == 2 && (
          <UserPacksTable
            header={[
              'Id_pack',
              'Titre',
              'Type',
              'Déscription',
              'Nbre max produits',
              'Prix',
              'Date',
              // 'Actions'
            ]}
            data={packs}
            // onClickUpdatePack={(idPack) => getPack(idPack)}
            // onClickDeletePack={(idPack) => {
            //   setOpenDeletePackConfirmDialog(true);
            //   setdeletedPackId(idPack);
            // }}
          /> 
        )}
        {activeTab == 1 && products.length === 0 && (
          <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>Aucun produit</div>
        )}
        {activeTab == 2 && packs.length === 0 && (
          <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>Aucun pack</div>
        )}
      </div>
    </div>
  );
}
