import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

import AdminHeader from './utils/components/header';
import ClientsTable from './utils/components/tables/clientsTable';
import getEnvironement, { getLang } from './environnement';
import AddClientDialog from './utils/components/dialog/addClientDialog';
import DeleteClientConfirmDialog from './utils/components/dialog/deleteClientConfirmDialog';
import UpdateClientDialog from './utils/components/dialog/updateClientDialog';
import searchIcon from './utils/assets/icons/search-normal.png';
import { userSelector } from './utils/store/userSlice';

export default function AdminClients() {
  const user = useSelector(userSelector);
  const [lang] = useState('FR');

  const [allClients, setAllClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [isEmptyClients, setIsEmptyClients] = useState(false);

  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [addClientErrors, setAddClientErrors] = useState(null);
  
  const [openUpdateClientDialog, setOpenUpdateClientDialog] = useState(false);
  const [updateClientErrors, setUpdateClientErrors] = useState(null);
  const [updatedClient, setUpdatedClient] = useState([]);

  const [openDeleteClientConfirmDialog, setOpenDeleteClientConfirmDialog] = useState(false);
  const [deletedClientId, setdeletedClientId] = useState(22);

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  const getClients = async () => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/users`;
    const params = {managerId: user.id};
    await axios.get(url, {params})
      .then((response) => {
        setAllClients(response.data);
        setClients(response.data);
        if (response.data.length == 0) {
          setIsEmptyClients(true);
        } else {
          setIsEmptyClients(false);
        }
      })
      .then(() => setCircularProgress(false))
      .catch((err) => {
        console.log(err);
      });

  };

  const getClient = async (id) => {
    setOpenBackDrop(true);
    const url = `${getEnvironement().API_URL}/admin/users/${id}`;
    await axios.get(url)
      .then((response) => {
        setUpdatedClient(response.data);
      })
      .then(() => setOpenBackDrop(false))
      .then(() => setOpenUpdateClientDialog(true))
      .catch((err) => {
        console.log(err);
      });
  };

  const addClient = async (client) => {
    const url = `${getEnvironement().API_URL}/admin/users`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.post(url, {manager: user.id, ...client}, config)
      .then(() => setAddClientErrors(null))
      .then(() => setOpenAddClientDialog(false))
      .then(() => getClients())
      .then(() => {
        toast.success(getLang(lang).clients.addClientSuccess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        console.log(err);
        setAddClientErrors(err.response.data.errors);
      });
  };

  const updateClient = async (client) => {
    const url = `${getEnvironement().API_URL}/admin/users/${client.id}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(url, client, config)
      .then(() => setUpdateClientErrors(null))
      .then(() => setOpenUpdateClientDialog(false))
      .then(() => getClients())
      .then(() => {
        toast.success(getLang(lang).clients.updateClientSuccess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch((err) => {
        setUpdateClientErrors(err.response.data.errors);
      });
  };

  const deleteClient = async (idClient) => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/users/${idClient}`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.delete(url, config)
      .then(() => getClients())
      .then(() => setCircularProgress(false))
      .then(() => {
        toast.success(getLang(lang).clients.deleteClientSuccess, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      })
      .catch(() => {
        getClients();
        setCircularProgress(false);
        toast.error(getLang(lang).clients.deleteClientError, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });
  };

  const handelChangeSearchInput = (e) => {
    setClients(allClients.filter(client => client.nom.startsWith(e.target.value)));
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className='py-4'>
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddClientDialog
        open={openAddClientDialog}
        onClose={() => {setOpenAddClientDialog(false); setAddClientErrors(null);}}
        onClickAddClient={addClient}
        idClient={deletedClientId}
        errors={addClientErrors}
      />
      <UpdateClientDialog
        open={openUpdateClientDialog}
        onClose={() => {setOpenUpdateClientDialog(false); setUpdateClientErrors(null);}}
        onClickUpdateClient={updateClient}
        client={updatedClient}
        errors={updateClientErrors}
      />
      <DeleteClientConfirmDialog
        open={openDeleteClientConfirmDialog}
        onClose={() => setOpenDeleteClientConfirmDialog(false)}
        onClickDeleteClient={() => {
          deleteClient(deletedClientId);
          setOpenDeleteClientConfirmDialog(false);
        }}
      />
      <AdminHeader name={user.nom} />
      <div className='flex justify-between items-center mb-5'>
        <p className='font-poppins font-medium text-2xl'>{getLang(lang).clients.allClients}</p>
        <Button
          variant='contained'
          onClick={() => setOpenAddClientDialog(true)}
        >
          {getLang(lang).clients.addNewClient}
        </Button>
      </div>
      <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
        <img src={searchIcon}/>
        <input
          type='search'
          placeholder={getLang(lang).clients.searchByName}
          onChange={(e) => handelChangeSearchInput(e)}
          className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
        />
      </div>
      <ClientsTable
        header={[
          getLang(lang).clients.tableHeader.idClient,
          getLang(lang).clients.tableHeader.lastName,
          getLang(lang).clients.tableHeader.firstName,
          getLang(lang).clients.tableHeader.email,
          getLang(lang).clients.tableHeader.phone,
          getLang(lang).clients.tableHeader.date,
          getLang(lang).clients.tableHeader.status,
          getLang(lang).clients.tableHeader.actions
        ]}
        data={clients}
        onClickUpdateClient={(idClient) => getClient(idClient)}
        onClickDeleteClient={(idClient) => {
          setOpenDeleteClientConfirmDialog(true);
          setdeletedClientId(idClient);
        }}
      />
      {isEmptyClients && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px] w-60vw'>{getLang(lang).clients.emptyClient}</div>
      )}
      {circularProgress && (
        <div className='flex justify-center p-5'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
